import {
    Component,
    OnInit,
    Optional,
    Inject,
    InjectionToken,
    OnDestroy
  } from "@angular/core";

  import {Router} from '@angular/router'

  import {
    transition,
    animate,
    style,
    trigger,
    state
  } from "@angular/animations";
  import { Subscription } from "rxjs";
  import { NotificationType, Notification, MessagesConfig } from "./notification-bar.type";
  import { NotificationBarService } from "./notification-bar.service";
  //import { MessagesConfig } from "../../message-config";
  import axios from 'axios';
  import { environment } from 'environments/environment';

  export const MESSAGES_CONFIG = new InjectionToken(
    "notification-bar.messages.config"
  );
  
  @Component({
    selector: "notification-bar",
    templateUrl: "./notification-bar.component.html",
    styleUrls: ["./notification-bar.component.css"],
    animations: [
      trigger("flyDown", [
        state(
          "in",
          style({
            opacity: 1,
            transform: "translateY(0)"
          })
        ),
        transition("void => *", [
          style({
            opacity: 0,
            transform: "translateY(-100%)"
          }),
          animate("200ms ease-in")
        ]),
        transition("* => void", [
          animate(
            "200ms ease-out",
            style({
              opacity: 0,
              transform: "translateY(-100%)"
            })
          )
        ])
      ])
    ]
  })
  export class NotificationBarComponent implements OnInit, OnDestroy {
  
    subscription: Subscription;
  
    constructor(
      public notificationBarService: NotificationBarService,
      protected router: Router,
      @Inject(MESSAGES_CONFIG) @Optional() private config?: MessagesConfig,
    ) {
      this.subscription = this.notificationBarService.onCreate.subscribe(
        this.addNotification.bind(this)
      );
    }
  
    ngOnInit() {
        
        const tenant = localStorage.getItem("tenant");
        this.notificationBarService.refreshNotifications(tenant);

    }

    addNotification(notification: Notification) {
      let newNotification = Object.assign({}, this.notificationBarService.defaults, notification);
      newNotification.typeValue = NotificationType[
        newNotification.type
      ].toLowerCase();

      newNotification.title = notification.title
      if (this.config && this.config.messages) {
        newNotification.message =
          this.config.messages[notification.message] || notification.message;
      }
  
      this.notificationBarService.notifications.push(newNotification);
  
      if (newNotification.autoHide) {
        window.setTimeout(() => {
          this.hideNotification(newNotification);
        }, newNotification.hideDelay);
      }
    }
  
    hideNotification(notification: Notification) {
      let index = this.notificationBarService.notifications.indexOf(notification);
      this.notificationBarService.notifications.splice(index, 1);
    }
  
    ngOnDestroy() {
      this.subscription.unsubscribe();
    }
  
    hideOnHover(notification: Notification) {
      if (notification.hideOnHover) {
        this.hideNotification(notification);
      }
    }

    clickRouteNotificacion(notification: Notification) {
        //console.log("Notification", notification);
        this.router.navigate([notification.routerLink]);
        this.hideNotification(notification);
    }

    clickLinkNotificacion(notificacion: Notification) {
        //console.log("Notification", notificacion);
        //this.router.navigate([notificacion.routerLink]);
        this.hideNotification(notificacion);
    }
  }
  