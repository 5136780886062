import { Component, ViewEncapsulation } from '@angular/core';
import { TabsControlService } from 'app/services/tabs.service';

@Component({
    selector     : 'content',
    templateUrl  : './content.component.html',
    styleUrls    : ['./content.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ContentComponent
{
    /**
     * Constructor
     */
    constructor(
        public tabsControlService : TabsControlService
    )
    {
    }
}
