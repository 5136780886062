export const environment = {
    version : require('../../package.json').version,
    production: true,
    /*firebaseConfig: {
        apiKey: "AIzaSyDDCZ0iUQOAXkgphvcNKxzWmL_MPnSnnCQ",
        authDomain: "facturasend.firebaseapp.com",
        projectId: "facturasend",
        storageBucket: "facturasend.appspot.com",
        messagingSenderId: "183233297538",
        appId: "1:183233297538:web:af0c15ab1ed9a33054889b",
        measurementId: "G-TRZ11EFG8B"
    },*/
    keycloakConfig : {
        clientId : 'facturacion-electronica-front',
        realm : 'FacturacionElectronicaRealm',
        url : 'https://keycloak.facturasend.com.py/auth/'
    },
    url: 'https://api.facturasend.com.py/',
    urlWSS: 'https://server.facturasend.com.py/',
    urlPWA: 'https://www.facturasend.com.py',
    publicAWSHost : 'https://s3.amazonaws.com/facturasend/app/',
    useCaptcha : true
};
