import { NgModule } from '@angular/core';
import { CallbackPipe } from './callback.pipe';
import { FirestoreDatePipe } from './firestore-date.pipe';

@NgModule({
  declarations: [FirestoreDatePipe, CallbackPipe],
  exports: [
    FirestoreDatePipe,
    CallbackPipe
  ]
})
export class PipesModule { }
